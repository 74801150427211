<template>

        <div class="ui p-half clearing red segment">
            <div class="downloadTab">
                <h3 class="ui left floated header m-0"> Liste des Tâches </h3>
            </div>

            <table class="ui selectable celled table">
                <thead>
                <tr>
                    <th class="filtrable-table">N° dossier</th>
                    <th>Immatriculation </th>
                    <th>Type </th>
                    <th>Propriétaire </th>
                    <th>Temps restant </th>
                    <th>Statut </th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="task in task_list">
                    <td class="danger-cell">{{task.dossier.numero || '--------'}}</td>
                    <td >{{task.dossier.vehicule.n_immat || '--------'}}</td>
                    <td >{{(task.dossier.vehicule && task.dossier.vehicule.marque) ? task.dossier.vehicule.marque.label || '--------' : '--------'}}</td>
                    <td >{{task.dossier.propri ? (task.dossier.propri.nom || '------') + ' '+ (task.dossier.propri.prenom || '-------') : '--------' }}</td>

                    <td  :class="taskStatus(task).class">
                        <countdown :time="deadline(task)" :interval="1000" tag="div" >
                            <template slot-scope="props">{{ props.days > 0 ? props.days+ ' jour(s),' : ''}}
                                {{ props.hours }} h, {{props.minutes }} m, {{props.seconds }} s
                            </template>
                        </countdown>
                    </td>

                    <td  :class="taskStatus(task).class">{{taskStatus(task).status}}</td>
                    <td @click="showModal(task)" style="cursor: pointer">
                        <i class="ui edit icon"></i>Edit
                    </td>
                </tr>
                </tbody>
            </table>

                <task-edit :current_task="selected_task"/>

        </div>

</template>

<script>
    import taskEdit from '@/components/taskCrud.vue'
    export default {
        name: "taskList",
        components:{
            taskEdit
        },
        data() {
            return {
                task_list: [],
                page: 1,
                limit: 10,
                count: 0,
                selected_task: {}
            }
        },
        methods: {
            getTasks() {
                log('getTasks');
                let query = {type:'link_task', 'action.task_status':'active', 'receiver._id': this.current_user._id};
                let pipe_line = [
                    {$sort:{date_creation:1}},
                    {
                        $graphLookup: {
                            from: this.$SHARED.collections.documents,
                            startWith: "$id_doc",
                            connectFromField: "id_doc",
                            connectToField: "_id",
                            maxDepth: 1,
                            as: "dossier"
                        }
                    },
                    {$unwind: "$dossier"},
                ];

                let args = {
                    collection: this.$SHARED.collections.documents,
                    query: query,
                    concat_pipeline: pipe_line,
                    options: {page: 1, limit: -1}
                };
                this.$store.requestFind(args).then(data => {
                    this.task_list = data.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages[code]});
                })
            },
            newTask(){
                this.$router.push({name: 'taskCrud'});
            },
            showModal(task){
                this.selected_task = task;
                this.$modal.show('taskCrud-modal', {current_task: task})
            },
            edit(current_task){
                this.$router.push({name: 'taskCrud', params:{current_task}});
            },
            taskStatus(task){
                let data = {};
                let now = new Date().getTime();

                if(now > task.deadline){
                    data.status = 'Hors delai';
                    data.class = 'danger-cell'
                }
                else if(now >task.deadline_warn && now < task.deadline ){
                    data.status = 'En approche';
                    data.class = 'warning-cell'
                }
                else if(now < task.deadline_warn){
                    data.status = 'En cours';
                    data.class = ''
                }
                return data;
            },
            deadline(task){
                let deadline = task.deadline - new Date().getTime();
                return (deadline > 0) ? deadline : 0
            },
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            }
        },
        mounted() {
            this.getTasks();
        }
    }
</script>

<style>
    .vm--container>.vm--modal{
        overflow: visible !important;
    }
</style>